import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingMainComponent } from './pages/landing-main/landing-main.component';
import { ROUTE_PATHS } from '../shared/constants/routes.constants';

const routes: Routes = [
  {
    path: ROUTE_PATHS.HOME,
    pathMatch: 'full',
    component: LandingMainComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingRoutingModule { }
