import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'rth-scroll-top-btn',
  templateUrl: './scroll-top-btn.component.html',
  styleUrl: './scroll-top-btn.component.scss'
})
export class ScrollTopBtnComponent implements OnInit {
  public isButtonVisible: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  public ngOnInit(): void {
    this.checkIsButtonVisible();
  }

  @HostListener('window:scroll', ['$event'])
  public checkIsButtonVisible(): void {
    if (isPlatformBrowser(this.platformId)) {
      let scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      this.isButtonVisible = scrollPercentage > 2;
    }
  }

  public scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
