<section class="contact" [id]="contactUsId"
         rthInView
         (isInView)="setVisibility($event)">
  <rth-wrapper>
    <section class="contact__wrapper">

      <div class="contact__images">
        <img src="assets/images/shapes/left-shape-corporate.svg"
             alt="shape"
             class="contact__images-shape">
        <img src="assets/images/shapes/left-dots-corporate.svg"
             alt="dots"
             class="contact__images-dots">
      </div>

      <form [formGroup]="landingForm" class="form" (ngSubmit)="onSubmit()">
        <rth-title>Contact us</rth-title>

        <div class="form__fields">
          <div class="form__block">
            <div class="form__block-input">
              <input formControlName="firstName"
                     type="text"
                     placeholder="First Name*">
              <div class="form__validation">
                <small *ngIf="validationService.isErrorDisplayed(landingForm, 'firstName', ['required'])"
                       class="form__message">
                  {{ REQUIRED_FIELD }}
                </small>
                <small *ngIf="validationService.isErrorDisplayed(landingForm, 'firstName', ['pattern'])"
                       class="form__message">
                  {{ VALIDATION_MESSAGES.FIRST_NAME }}
                </small>
              </div>
            </div>

            <div class="form__block-input">
              <input formControlName="lastName"
                     type="text"
                     placeholder="Last Name*">
              <div class="form__validation">
                <small *ngIf="validationService.isErrorDisplayed(landingForm, 'lastName', ['required'])"
                       class="form__message">
                  {{ REQUIRED_FIELD }}
                </small>
                <small *ngIf="validationService.isErrorDisplayed(landingForm, 'lastName', ['pattern'])"
                       class="form__message">
                  {{ VALIDATION_MESSAGES.LAST_NAME }}
                </small>
              </div>
            </div>
          </div>

          <div class="form__input">
            <input formControlName="email"
                   placeholder="Email*"
                   type="email">
            <div class="form__validation">
              <small *ngIf="validationService.isErrorDisplayed(landingForm, 'email', ['required'])"
                     class="form__message">
                {{ REQUIRED_FIELD }}
              </small>
              <small
                *ngIf="validationService.isErrorDisplayed(landingForm, 'email', ['email', 'minlength', 'maxlength'])"
                class="form__message">
                {{ VALIDATION_MESSAGES.EMAIL }}
              </small>
            </div>
          </div>

          <div class="form__textarea">
            <textarea formControlName="message" rows="4" placeholder="Message*"></textarea>
            <div class="form__validation">
              <small *ngIf="validationService.isErrorDisplayed(landingForm, 'message', ['required'])"
                     class="form__message">
                {{ REQUIRED_FIELD }}
              </small>
              <small *ngIf="validationService.isErrorDisplayed(landingForm, 'message', ['pattern'])"
                     class="form__message">
                {{ VALIDATION_MESSAGES.MESSAGE }}
              </small>
            </div>
          </div>
        </div>

        <div class="form__button">
          <rth-button [isDisabled]="landingForm.invalid">Send message</rth-button>
        </div>
      </form>
    </section>
  </rth-wrapper>
</section>
