import { Component, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ButtonTypes } from '../../../shared/models/button.types';
import { GuideStep } from '../../models/guide-step';
import { isPlatformBrowser } from "@angular/common";
import { Router } from '@angular/router';
import { ROUTES } from '../../../shared/constants/routes.constants';

@Component({
  selector: 'rth-steps-guide',
  templateUrl: './steps-guide.component.html',
  styleUrls: ['./steps-guide.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepsGuideComponent {
  protected readonly ButtonTypes = ButtonTypes;

  public leftShapeSrc: string = 'assets/images/shapes/left-shape-corporate.svg';
  public rightShapeSrc: string = 'assets/images/shapes/right-shape-corporate.svg';
  public leftDotsSrc: string = 'assets/images/shapes/left-dots-corporate.svg';
  public rightDotsSrc: string = 'assets/images/shapes/right-dots-corporate.svg';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }

  public steps: GuideStep[] = [
    {
      title: 'Step 1: Register Your eCommerce Platform',
      text: 'Register your eCommerce platform with RetailHub Invoice Aggregator using a single endpoint. Provide details about your platform, choose the integration type, and authenticate securely.',
      example: '```json\n' +
        '{\n' +
        '  "integrationType": "Your Integration Name",\n' +
        '  "apiKey": "your_integration_api_key"\n' +
        '}\n' +
        '```'
    },
    {
      title: 'Step 2: Use Centralized Aggregator for Invoice Management',
      text: 'Utilize the centralized aggregator to view and export your invoices effortlessly. Fetch all your invoices from one endpoint and simplify your invoice management.',
      example: '```http\n' +
        'GET /integrations/{integrationId}/invoices\n' +
        'Host: invoicesaggregtorapi.retailhub.codexio.bg\n' +
        'Authorization: Bearer your_access_token\n' +
        '```'
    }
  ];

  public copyToClipboard(step: GuideStep, index: number): void {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(step.example)
      .then(() => {
        const codeNode: HTMLElement | null = document.getElementById('step-' + index);
        const range: Range = document.createRange();

        if (codeNode) {
          range.selectNode(codeNode);
          const selection: Selection = window.getSelection()!;
          selection.removeAllRanges();
          selection.addRange(range);
        }
      })
      .catch(() => {
        alert('Unable to copy code. Please copy manually');
      });
    }
  }

  public navigateToDocs(): void {
    this.router.navigate([ROUTES.DOCS]);
  }
}
