import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './base/navigation/navigation.component';
import { BaseComponent } from './base/base.component';
import { SharedModule } from './modules/shared/shared.module';
import { FooterComponent } from './base/footer/footer.component';
import { ScrollTopBtnComponent } from './base/scroll-top-btn/scroll-top-btn.component';
import { DocumentationRoutingModule } from './modules/documentation/documentation-routing.module';
import { LandingRoutingModule } from './modules/landing/landing-routing.module';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    BaseComponent,
    FooterComponent,
    ScrollTopBtnComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    DocumentationRoutingModule,
    LandingRoutingModule,
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient })
  ],
  providers: [
    provideHttpClient(withFetch()),
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
