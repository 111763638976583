import { Component } from '@angular/core';
import { ButtonTypes } from '../../../shared/models/button.types';
import { SectionsIdConstants } from '../../../shared/constants/sections-id.constants';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

@Component({
  selector: 'rth-pricing-plans',
  templateUrl: './pricing-plans.component.html',
  styleUrls: ['./pricing-plans.component.scss']
})
export class PricingPlansComponent {
  protected readonly ButtonTypesConstants = ButtonTypes;
  protected readonly pricingId = SectionsIdConstants.PRICING;

  public pricingPlans = [
    {
      name: 'Basic',
      price: '200',
      included: [
        'Unified Integration Hub',
        'Authentication Security (OAuth 2.0)',
        'Scheduled Data Access (Updates once a day)',
        'Limited to 3 Integrations',
      ],
      excluded: [
        'Real-time Data Access',
        'Premium Support'
      ],
      isSelected: false
    },
    {
      name: 'Pro',
      price: '400',
      included: [
        'Unified Integration Hub',
        'Authentication Security (OAuth 2.0)',
        'Real-time Data Access (Instant updates)',
        'Up to 7 Integrations'
      ],
      excluded: [
        'Premium Support'
      ],
      isSelected: true
    },
    {
      name: 'Premium',
      price: null,
      included: [
        'Unified Integration Hub',
        'Authentication Security (OAuth 2.0)',
        'Real-time Data Access (Instant updates)',
        'Scalable Performance',
        'Up to 15 Integrations',
        'Premium Support'
      ],
      excluded: [],
      isSelected: false
    }
  ];

  constructor(public navigationService: NavigationService) { }

  public setVisibility(isVisible: boolean): void {
    this.navigationService.setSectionVisibility(this.pricingId, isVisible);
  }
}
