import { Component } from '@angular/core';
import { PRODUCT_NAME } from './modules/shared/constants/global.constants';

@Component({
  selector: 'rth-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title: string = PRODUCT_NAME;
}
