<div *ngIf="notificationService.data"
     class="notification__wrapper"
     [ngClass]="notificationService.data.type?.toString()"
     [@openClose]="notificationService.data && notificationService.data.show ? 'open' : 'closed'">

  <div class="notification__close">
    <i class="fi fi-rs-cross" (click)="closeNotification()"></i>
  </div>

  <div class="notification">
    <div class="notification__success-icon">
      <i class="fi fi-rs-exclamation" *ngIf="notificationService.data.type === MessageType.error"></i>
      <i class="fi fi-rs-check-circle" *ngIf="notificationService.data.type === MessageType.success"></i>
    </div>

    <div>
      <div class="notification__content">
        <h5 class="notification__title">
          {{ notificationService.data.title }}
        </h5>

        <div class="notification__text">
          {{ notificationService.data.content }}
        </div>
      </div>
    </div>
  </div>

  <div class="notification__progress-bar" [style.width.%]="notificationService.data.progressWidth"></div>
</div>

