<section class="insights">
  <rth-wrapper>
    <div class="insights__bg">
      <div class="insights__list">
        <p class="insights__benefit">
          Reduce development time by
          <span>30%</span>
          with our pre-built connectors.
        </p>
        <div class="insights__line"></div>
        <p class="insights__benefit">
          Minimize errors in invoice generation, cutting operational costs by
          <span>20%</span>.
        </p>
      </div>
    </div>
  </rth-wrapper>
</section>
