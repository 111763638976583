import { Component } from '@angular/core';
import { ButtonTypes } from '../../../shared/models/button.types';
import { PRODUCT_NAME } from '../../../shared/constants/global.constants';
import { SectionsIdConstants } from '../../../shared/constants/sections-id.constants';

@Component({
  selector: 'rth-opening',
  templateUrl: './opening.component.html',
  styleUrls: ['./opening.component.scss']
})
export class OpeningComponent {
  protected readonly ButtonTypes = ButtonTypes;
  protected readonly COMPANY_NAME = PRODUCT_NAME;
  protected readonly SectionsIdConstants = SectionsIdConstants;

  public imageSrc: string = 'assets/images/opening/opening-img.svg';

  public scrollToSection(sectionId: SectionsIdConstants): void {
    const element = document.getElementById(sectionId);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
