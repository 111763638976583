<section class="specifications">
  <rth-wrapper>
    <rth-title class="specifications__title">Why choose {{ COMPANY_NAME }}</rth-title>
    <rth-subtitle class="specifications__subtitle">One API to rule them all</rth-subtitle>

    <section class="specifications__list">
      <div *ngFor="let specification of specifications"
           class="specifications__card">
        <i [ngClass]="specification.iconClass" class="specifications__card-icon"></i>
        <h4 class="specifications__card-title">{{ specification.title }}</h4>
        <p class="specifications__card-text">{{ specification.description }}</p>
      </div>
    </section>
  </rth-wrapper>
</section>
