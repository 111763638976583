import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SectionsIdConstants } from '../../modules/shared/constants/sections-id.constants';
import { NavigationService } from '../../modules/shared/services/navigation/navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTES } from '../../modules/shared/constants/routes.constants';
import { filter } from 'rxjs';
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'rth-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  protected readonly SectionsIdConstants = SectionsIdConstants;

  public isNavOpen: boolean = false;
  public isMobileResolution: boolean = false;
  public isPageScrolled: boolean = false;
  public logoUrl: string;
  public logoLargeMonochrome: string = 'assets/images/logos/codexio-logo-horizontal-monochrome-light.svg';
  public logoSmallMonochrome: string = 'assets/images/logos/codexio-logo-single-monochrome.svg';
  public logoLargeColored: string = 'assets/images/logos/codexio-logo-horizontal-dark.svg';
  public logoSmallColored: string = 'assets/images/logos/codexio-logo-single.svg';
  public activeElementId: string = SectionsIdConstants.NAVIGATION;

  public navLinks = [
    {
      title: 'Features',
      href: ROUTES.HOME,
      sectionId: SectionsIdConstants.FEATURES
    },
    {
      title: 'Pricing',
      href: ROUTES.HOME,
      sectionId: SectionsIdConstants.PRICING
    },
    {
      title: 'Integrations',
      href: ROUTES.HOME,
      sectionId: SectionsIdConstants.INTEGRATIONS
    },
    {
      title: 'Contact us',
      href: ROUTES.HOME,
      sectionId: SectionsIdConstants.CONTACT_US
    },
    {
      title: 'Docs',
      href: ROUTES.DOCS,
      sectionId: SectionsIdConstants.NAVIGATION
    }
  ];

  constructor(
    public navigationService: NavigationService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public ngOnInit(): void {
    this.checkIsPageScrolled();
    this.checkNavigationType();
    this.setScrollLocation();
    this.navigationService.setSectionVisibility(SectionsIdConstants.NAVIGATION, true);
  }

  public navigateToPage(link: any): void {
    const currentRoute: string = this.router.url;
    this.activeElementId = link.sectionId;

    if (currentRoute !== link.href) {
      this.router.navigate([link.href]);
      this.navigationService.setSectionVisibility(link.sectionId, true);
    } else {
      this.scrollToElement(this.activeElementId);
    }
  }

  public setScrollLocation(): void {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(() => {
        setTimeout(() => {
          this.scrollToElement(this.activeElementId);
        }, 0);
      });
  }

  public scrollToElement(id: string): void {
    if(!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.activeElementId = id;
    const element: HTMLElement | null = document.getElementById(id);

    if(!element || this.activeElementId === SectionsIdConstants.NAVIGATION) {
      window.scrollTo(0, 0);
      window.scroll({ behavior: 'smooth' });
      return;
    }

    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  @HostListener('window:resize', ['$event'])
  public checkNavigationType(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkIsPageScrolled();

      if (window.innerWidth < 768) {
        this.isMobileResolution = true;
        this.isNavOpen = false;
        this.setLogo();
        return;
      }
    }
    this.isMobileResolution = false;
    this.isNavOpen = true;
    this.setLogo();
  }

  @HostListener('window:scroll', ['$event'])
  public checkIsPageScrolled(): void {
    this.navigationService.isNavigationScrolled().subscribe((response) => {
      this.isPageScrolled = response;
      this.setLogo();
    });
  }

  @HostListener('window:click', ['$event'])
  private handleClickOutsideNavDropdown(event: Event): void {
    const navVisibilityButton: Element = document.getElementsByClassName('nav__burger-menu')[0];

    if (navVisibilityButton && !navVisibilityButton.contains(event.target as HTMLElement)) {
      this.isNavOpen = false;
    }
  }

  public isLinkActive(link: any): boolean {
    if (this.router.url !== link.href) return false;
    return this.navigationService.isSectionVisible(link.sectionId);
  }

  public showHideNav(): void {
    this.isNavOpen = !this.isNavOpen;
  }

  private setLogo(): void {
    if(this.isPageScrolled) {
      this.logoUrl = this.isMobileResolution ? this.logoSmallColored : this.logoLargeColored;
      return;
    }

    this.logoUrl = this.isMobileResolution ? this.logoSmallMonochrome : this.logoLargeMonochrome;
  }
}
