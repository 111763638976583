import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ValidationService } from '../../../shared/services/validation/validation.service';
import { EMAIL, VALIDATION_PATTERNS } from '../../../shared/constants/validation.constants';
import { SectionsIdConstants } from '../../../shared/constants/sections-id.constants';
import { VALIDATION_MESSAGES, REQUIRED_FIELD } from '../../../shared/constants/validation-messages.constants';
import { LandingService } from '../../services/landing.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { MessageType } from '../../../shared/models/success-message.types';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

@Component({
  selector: 'rth-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  protected readonly contactUsId = SectionsIdConstants.CONTACT_US;
  protected readonly VALIDATION_MESSAGES = VALIDATION_MESSAGES;
  protected readonly REQUIRED_FIELD = REQUIRED_FIELD;

  public landingForm: FormGroup;

  constructor(
    public validationService: ValidationService,
    private formBuilder: FormBuilder,
    private landingService: LandingService,
    private notificationService: NotificationService,
    private navigationService: NavigationService
  ) { }

  public ngOnInit(): void {
    this.buildForm();
  }

  public onSubmit(): void {
    if (this.landingForm.invalid) {
      Object.keys(this.landingForm.controls).forEach((controlName) => {
        this.landingForm.get(controlName)?.markAsTouched();
      });

      return;
    }

    this.landingService.sendContactData(this.landingForm.value).subscribe({
      next: (() => {
        this.notificationService.initiate({
          title: 'Success',
          content: 'Your message was sent successfully!',
          type: MessageType.success
        });
      }),
      error: ((error) => {
        this.notificationService.initiate({
          title: 'Error',
          content: error.error.error.message,
          type: MessageType.error
        });
      })
    });

    this.landingForm.reset();
  }

  public setVisibility(isVisible: boolean): void {
    this.navigationService.setSectionVisibility(this.contactUsId, isVisible);
  }

  private buildForm(): void {
    this.landingForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(VALIDATION_PATTERNS.FIRST_NAME)]],
      lastName: ['', [Validators.required, Validators.pattern(VALIDATION_PATTERNS.LAST_NAME)]],
      email: ['', [Validators.required, Validators.email, Validators.minLength(EMAIL.MIN_LENGTH), Validators.maxLength(EMAIL.MAX_LENGTH)]],
      message: ['', [Validators.required, Validators.pattern(VALIDATION_PATTERNS.MESSAGE)]]
    });
  }
}
