import { Component } from '@angular/core';
import { SectionsIdConstants } from '../../../shared/constants/sections-id.constants';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

@Component({
  selector: 'rth-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent {
  protected readonly featuresId = SectionsIdConstants.FEATURES;

  public features = [
    {
      title: 'One API. All Platforms.',
      imgSrc: 'assets/images/features/platform-corporate.png',
      text: 'Simplify invoice management with our centralized API, accommodating diverse eCommerce platforms effortlessly.'
    },
    {
      title: 'Secure Data Handling',
      imgSrc: 'assets/images/features/data-security-corporate.png',
      text: 'Trust us with your data. We adhere to strict security standards and protocols, ensuring the confidentiality and integrity of your information.'
    },
    {
      title: 'Customized Business Solutions',
      imgSrc: 'assets/images/features/customize-corporate.png',
      text: 'Your business, your way. Benefit from our flexible plans tailored to meet your unique needs and empower your invoicing process.'
    }
  ];

  constructor(public navigationService: NavigationService) { }

  public setVisibility(isVisible: boolean): void {
    this.navigationService.setSectionVisibility(this.featuresId, isVisible);
  }
}
