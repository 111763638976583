import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  public isInvalidField(form: FormGroup, fieldName: string): boolean | undefined {
    return form.get(fieldName)?.invalid && (form.get(fieldName)?.touched || form.get(fieldName)?.dirty);
  }

  public isErrorFound(form: FormGroup, fieldName: string, errorType: string): boolean {
    return form.get(fieldName)?.errors?.[errorType];
  }

  public isErrorDisplayed(form: FormGroup, fieldName: string, errorTypes: string[]): boolean {
    if (!this.isInvalidField(form, fieldName)) {
      return false;
    }

    return errorTypes.some((errorType: string): boolean => this.isErrorFound(form, fieldName, errorType));
  }
}
