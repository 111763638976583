<section class="opening">
  <rth-wrapper>
    <div class="opening__container">
      <div class="opening__data">
        <div class="opening__content">
          <div class="opening__title">
            Seamless Integration, Effortless Invoicing with {{ COMPANY_NAME }}
          </div>
          <p class="opening__text">
            Elevate your business efficiency with our personalized Invoice Aggregator API for eCommerce platforms.
          </p>
          <rth-button (click)="scrollToSection(SectionsIdConstants.CONTACT_US)" [buttonType]="ButtonTypes.LIGHT_OUTLINE">Request demo!</rth-button>
        </div>
        <div class="opening__img-wrapper">
          <img [src]="imageSrc" class="opening__img">
        </div>
      </div>

      <button class="opening__scroll-down" (click)="scrollToSection(SectionsIdConstants.FEATURES)">
        <i class="fi fi-rs-angle-down"></i>
      </button>
    </div>
  </rth-wrapper>
</section>
