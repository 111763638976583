export const FIRST_NAME = {
  MIN_LENGTH:  2,
  MAX_LENGTH:  50
};
export const LAST_NAME = {
  MIN_LENGTH:  2,
  MAX_LENGTH:  50
};
export const EMAIL = {
  MIN_LENGTH:  2,
  MAX_LENGTH:  100
};
export const MESSAGE = {
  MIN_LENGTH:  5,
  MAX_LENGTH:  300
};

export const VALIDATION_PATTERNS = {
  FIRST_NAME: new RegExp(`^((?!.*\\s{2})[A-Za-z][A-Za-z'\\s.-]{${FIRST_NAME.MIN_LENGTH-1},${FIRST_NAME.MAX_LENGTH-1}}$)`),
  LAST_NAME: new RegExp(`^((?!.*\\s{2})[A-Za-z][A-Za-z'\\s.-]{${LAST_NAME.MIN_LENGTH-1},${LAST_NAME.MAX_LENGTH-1}}$)`),
  MESSAGE: new RegExp(`^((?!.*\\s{2})[a-zA-Z][^*&~]{${MESSAGE.MIN_LENGTH - 1},${MESSAGE.MAX_LENGTH - 1}}$)`)
};
