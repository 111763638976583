<rth-wrapper>
  <section [id]="pricingId"
           class="pricing"
           rthInView
           (isInView)="setVisibility($event)">

    <rth-title class="pricing__title">Choose your plan</rth-title>
    <div class="pricing__list">
      <section *ngFor="let plan of pricingPlans"
               class="pricing__plan"
               [class.selected]="plan.isSelected">

        <h4 *ngIf="plan.price; else letsTalk" class="pricing__plan-cost">
          <span class="pricing__plan-currency">&#8364;</span>
          <span class="pricing__plan-price">{{ plan.price }}</span>
        </h4>
        <ng-template #letsTalk>
          <h4 class="pricing__plan-cost custom">Let's talk!</h4>
        </ng-template>

        <h4 class="pricing__plan-name">{{ plan.name }}</h4>

        <ul class="pricing__plan-benefits">
          <li *ngFor="let includedItem of plan.included" class="included">
            <i class="fi fi-rs-check"></i>{{ includedItem }}
          </li>

          <li *ngFor="let excludedItem of plan.excluded" class="excluded">
            <i class="fi fi-rs-cross"></i> {{ excludedItem }}
          </li>
        </ul>

        <div class="pricing__plan-btn">
          <rth-button>Choose plan</rth-button>
        </div>
      </section>
    </div>
  </section>
</rth-wrapper>
