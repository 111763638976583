<header [class.scrolled]="isPageScrolled" [id]="SectionsIdConstants.NAVIGATION">
  <rth-wrapper class="nav__wrapper">
    <section class="nav__content">
      <div class="nav__logo">
        <a href="/">
          <img [src]="logoUrl" class="nav__logo-img">
        </a>
      </div>

      <div class="nav__menu">
        <i *ngIf="isMobileResolution"
           (click)="showHideNav()"
           class='bx bx-menu nav__burger-menu'>
        </i>
        <nav *ngIf="isNavOpen">
          <a *ngFor="let link of navLinks"
             [class.active]="isLinkActive(link)"
             (click)="navigateToPage(link)">
            {{ link.title }}
          </a>
        </nav>
      </div>
    </section>
  </rth-wrapper>
</header>
