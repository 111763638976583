<rth-wrapper>
  <section [id]="integrationsId"
           rthInView (isInView)="setVisibility($event)"
           [class.animated]="isAnimated"
           class="integrations">

    <rth-title class="integrations__title">Platform integrations</rth-title>
    <div class="integrations__list">
      <a *ngFor="let platform of platforms; let i = index"
         [href]="platform.linkSrc"
         rel="noopener"
         target="_blank"
         class="integrations__list-item"
         [style.animation-delay]="i * 0.2 + 's'">

        <img [src]="platform.imgSrc" [alt]="platform.name">
      </a>
    </div>
  </section>
</rth-wrapper>
