import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

@Component({
  selector: 'rth-documentation-main',
  templateUrl: './documentation-main.component.html',
  styleUrls: ['./documentation-main.component.scss']
})
export class DocumentationMainComponent implements OnInit, OnDestroy {
  constructor(private navigationService: NavigationService) { }

  public ngOnInit(): void {
    this.navigationService.setScrollPercentageBreakpoint(-1);
  }

  public ngOnDestroy(): void {
    this.navigationService.resetScrollBreakpoint();
  }
}
