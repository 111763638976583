import { Directive, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[rthInView]'
})
export class InViewDirective implements OnInit, OnDestroy {
  @Output() isInView: EventEmitter<boolean> = new EventEmitter<boolean>();

  private intersectionObserver: IntersectionObserver;

  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver = new IntersectionObserver(entries => {
          entries.forEach((entry) => {
            const isVisible: boolean = entry.isIntersecting;
            this.isInView.emit(isVisible);
          });
        },
        {
          threshold: [0.6, 1]
        }
      );

      this.intersectionObserver.observe(this.elementRef.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }
}
