import { Component, Input } from '@angular/core';
import { ButtonTypes } from '../../models/button.types';

@Component({
  selector: 'rth-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() buttonType: ButtonTypes = ButtonTypes.MAIN_OUTLINE;
  @Input() isDisabled: boolean = false;
}
