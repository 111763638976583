<footer class="footer" id="{{ SectionsIdConstants.FOOTER }}">
  <section class="footer__container">
    <rth-wrapper>
      <div class="footer__data">
        <div class="footer__links">
          <div class="footer__contacts">
            <div *ngFor="let contact of contacts"
                 class="footer__contact">
              <i [class]="contact.iconClass"></i>
              <a [href]="contact.href"
                 target="_blank"
                 rel="noopener"
                 class="footer__contact-link">{{ contact.text }}</a>
            </div>
          </div>

          <div class="footer__socials">
            <a *ngFor="let link of socialLinks"
               [href]="link.href"
               target="_blank"
               rel="noopener"
               class="footer__socials__link">
              <i [class]='link.iconClass'></i>
            </a>
          </div>
        </div>

        <div class="footer__logo">
          <img [src]="logoSrc" [alt]="COMPANY_NAME">
        </div>
      </div>

      <p class="footer__copyright">{{ currentYear }} © {{ COMPANY_FULL_NAME }}</p>
    </rth-wrapper>
  </section>
</footer>
