<div class="guide">
  <rth-wrapper>
    <section class="guide__content">
      <rth-title class="guide__title">How it works</rth-title>

      <div *ngFor="let step of steps; let i = index" class="guide__steps">
        <div class="guide__step" [ngClass]="i % 2 ? 'odd' : 'even'">
          <div class="guide__step-data">
            <h4 class="guide__step-title">{{ step.title }}</h4>
            <p class="guide__step-text">{{ step.text }}</p>
          </div>

          <div class="guide__step-example">
            <img class="guide__step-shape" [src]="i % 2 ? rightShapeSrc : leftShapeSrc">
            <img class="guide__step-dots" [src]="i % 2 ? rightDotsSrc : leftDotsSrc">

            <div class="guide__step-example-code">
              <span class="guide__step-example-icon" (click)="copyToClipboard(step, i)">
                <i class="fi fi-rs-copy-alt"></i>
              </span>
              <pre [id]="'step-' + i"><code markdown>{{ step.example }}</code></pre>
            </div>
          </div>
        </div>
      </div>

      <div class="guide__btn">
        <rth-button [buttonType]="ButtonTypes.MAIN_FILLED"
                    (click)="navigateToDocs()">
          Explore the docs
        </rth-button>
      </div>
    </section>
  </rth-wrapper>
</div>

