import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HTTP_CONSTANTS } from '../constants/http.constants';
import { StrapiDataResponse } from '../../shared/constants/strapi-data.response';
import { ContactDataRequest } from '../constants/contact-data.request';
import { ContactDataResponse } from '../constants/contact-data.response';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  constructor(private http: HttpClient) { }

  public sendContactData(data: ContactDataRequest): Observable<StrapiDataResponse<ContactDataResponse>> {
    return this.http.post<StrapiDataResponse<ContactDataResponse>>(HTTP_CONSTANTS.CONTACT_US, { data });
  }
}
