import { Component } from '@angular/core';
import { PRODUCT_NAME } from '../../../shared/constants/global.constants';

@Component({
  selector: 'rth-specifications',
  templateUrl: './specifications.component.html',
  styleUrls: ['./specifications.component.scss']
})
export class SpecificationsComponent {
  protected readonly COMPANY_NAME: string = PRODUCT_NAME;

  public specifications = [
    {
      title: 'Unified Integration Hub',
      description: 'Seamlessly connect with various eCommerce platforms through Invoice Aggregator API. Our standardized interface serves as a centralized hub for effortless integration.',
      iconClass: 'ffi fi-ts-gallery-thumbnails'
    },
    {
      title: 'Robust Authentication Security',
      description: 'Ensure secure integration with Invoice Aggregator API. We leverage OAuth 2.0, providing both seamless access and authorized protection for your resources.',
      iconClass: 'fi fi-ts-shield-keyhole'
    },
    {
      title: 'Effortless Version Control',
      description: 'Stay ahead effortlessly. Invoice Aggregator API simplifies version control, making it easy for you to manage and update your integration without any hassle.',
      iconClass: 'fi fi-ts-code-compare'
    },
    {
      title: 'Real-time Data Access',
      description: 'Empower your applications with real-time data access. Invoice Aggregator API offers swift and efficient access to the latest information, keeping your systems synchronized.',
      iconClass: 'fi fi-ts-stats'
    },
    {
      title: 'Scalable Performance',
      description: 'Scale effortlessly with Invoice Aggregator API. Designed for optimal performance, our API ensures smooth scalability to handle increased loads as your business grows.',
      iconClass: 'fi fi-ts-challenge-alt'
    },
    {
      title: 'Developer-Friendly Documentation',
      description: 'Streamline development with Invoice Aggregator API\'s developer-friendly documentation. Clear insights and guidance make the integration process a breeze for developers.',
      iconClass: 'fi fi-ts-features'
    },
  ];
}
