<div [id]="featuresId"
     class="features"
     rthInView
     (isInView)="setVisibility($event)">
  <rth-wrapper>
    <div class="features__cards">
      <div *ngFor="let feature of features" class="features__card">
        <div class="features__card__icon">
          <img [src]="feature.imgSrc">
        </div>
        <h5 class="features__card__title">{{ feature.title }}</h5>
        <p class="features__card__text">{{ feature.text }}</p>
      </div>
    </div>
  </rth-wrapper>
</div>
