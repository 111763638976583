import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from "@angular/common";
import { SectionsIdConstants } from '../../constants/sections-id.constants';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public scrollPercentage: number = 0;
  public defaultScrollBreakpoint: number = 2;

  private scrollPercentageBreakpoint$: BehaviorSubject<number> = new BehaviorSubject<number>(this.defaultScrollBreakpoint);
  private isNavScrolled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private sectionVisibilityMap: { [key in SectionsIdConstants]: boolean } = { } as { [key in SectionsIdConstants]: boolean };

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.scrollPercentageBreakpoint$.subscribe((response) => {
      this.isNavigationScrolled();
    });

    Object.values(SectionsIdConstants).forEach(section => {
      this.sectionVisibilityMap[section] = false;
    });
  }

  public setScrollPercentageBreakpoint(percentage: number): void {
    this.scrollPercentageBreakpoint$.next(percentage);
  }

  public resetScrollBreakpoint(): void {
    this.scrollPercentageBreakpoint$.next(this.defaultScrollBreakpoint);
  }

  public getScrollPercentageBreakpoint(): Observable<number> {
    return this.scrollPercentageBreakpoint$;
  }

  public isNavigationScrolled(): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollPercentage = (window.scrollY * 100) / window.innerHeight;
    } else {
      this.scrollPercentage = 0;
    }
    this.isNavScrolled$.next(this.scrollPercentage >= this.scrollPercentageBreakpoint$.value)
    return this.isNavScrolled$;
  }

  public isSectionVisible(sectionId: SectionsIdConstants | null): boolean {
    return sectionId ? this.sectionVisibilityMap[sectionId] : false;
  }

  public setSectionVisibility(sectionId: SectionsIdConstants, isVisible: boolean): void {
    this.sectionVisibilityMap[sectionId] = isVisible;
  }
}
