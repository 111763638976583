import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NotificationService } from '../../services/notification/notification.service';
import { SuccessMessageData } from '../../models/success-message.data';
import { MessageType } from '../../models/success-message.types';

@Component({
  selector: 'rth-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          opacity: 0,
          right: '-2rem',
        })
      ),
      state(
        'open',
        style({
          right: '2rem',
        }),
      ),
      transition('open <=> closed', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class NotificationComponent implements OnInit {
  protected readonly MessageType = MessageType;

  constructor(public notificationService: NotificationService){ }

  public ngOnInit(): void {
    this.showNotification();
  }

  public showNotification(): void {
    this.notificationService.open.subscribe((data: SuccessMessageData): void => {
      if (data.show) {
        this.countDown();
      }
    });
  }

  public countDown(): void {
    const progressInterval = setInterval(() => {
      if (this.notificationService.data.progressWidth) {
        this.notificationService.data.progressWidth -= 0.5;

        if (this.notificationService.data.progressWidth <= 0) {
          this.notificationService.hide();
          clearInterval(progressInterval);
        }
      }
    }, 60);
  }

  public closeNotification(): void {
    this.notificationService.data.progressWidth = 0;
    this.notificationService.hide();
  }
}
