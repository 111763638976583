import { Component } from '@angular/core';
import { SectionsIdConstants } from '../../../shared/constants/sections-id.constants';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

@Component({
  selector: 'rth-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent {
  protected readonly integrationsId = SectionsIdConstants.INTEGRATIONS;

  public isAnimated: boolean = false;
  public platforms = [
    {
      name: 'Etsy',
      imgSrc: './assets/images/integrations-logos/etsy.png',
      linkSrc: 'https://www.etsy.com/',
    },
    {
      name: 'CloudCart',
      imgSrc: './assets/images/integrations-logos/cloudcart.png',
      linkSrc: 'https://cloudcart.com/bg',
    },
    {
      name: 'Emag',
      imgSrc: './assets/images/integrations-logos/emag.png',
      linkSrc: 'https://www.emag.bg/',
    },
    {
      name: 'Olx',
      imgSrc: './assets/images/integrations-logos/olx.png',
      linkSrc: 'https://www.olx.bg/',
    },
    {
      name: 'WooCommerce',
      imgSrc: './assets/images/integrations-logos/woocommerce.png',
      linkSrc: 'https://woo.com/',
    }
  ];

  constructor(public navigationService: NavigationService) { }

  public setVisibility(isVisible: boolean): void {
    this.isAnimated = isVisible;
    this.navigationService.setSectionVisibility(this.integrationsId, isVisible);
  }
}
