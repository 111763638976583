import { Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'rth-docs-content',
  templateUrl: './documentation-content.component.html',
  styleUrls: ['./documentation-content.component.scss']
})
export class DocumentationContentComponent {
  public isMarkdownLoaded: boolean = false;
  public readonly docsSrc: string = '../../../assets/documentation/docs-v2.md';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  public onMarkdownLoad(): void {
    const markdown: Element = document.getElementsByClassName('markdown')[0];
    this.applyTailwindStyles(markdown);
    this.isMarkdownLoaded = true;
  }

  private applyTailwindStyles(element: Element): void {
    const pElements = element.querySelectorAll('p');
    pElements.forEach((p) => {
      p.classList.add('my-5');
    });

    const h3Elements = element.querySelectorAll('h3');
    h3Elements.forEach((h3) => {
      h3.classList.add('text-3xl', 'font-semibold', 'my-5');
    });

    const h4Elements = element.querySelectorAll('h4');
    h4Elements.forEach((h4) => {
      h4.classList.add('text-2xl', 'font-semibold', 'my-5');
    });

    const h5Elements = element.querySelectorAll('h5');
    h5Elements.forEach((h5) => {
      h5.classList.add('text-xl', 'font-semibold', 'my-2');
    });

    const h6Elements = element.querySelectorAll('h6');
    h6Elements.forEach((h6) => {
      h6.classList.add('text-lg', 'font-semibold', 'my-2');
    });

    const codeElements = element.querySelectorAll('code');
    codeElements.forEach((code) => {
      code.classList.add('bg-corporate/15', 'break-words');
    });

    const ulElements = element.querySelectorAll('ul');
    ulElements.forEach((ul) => {
      ul.classList.add('ml-10', 'list-disc');
    });

    const preElements = element.querySelectorAll('pre');
    preElements.forEach((pre) => {
      pre.classList.add('border', 'border-dark-20/20', 'p-5', 'my-5', 'overflow-auto', 'bg-dark-20/10');

      const nestedCodeElements = pre.querySelectorAll('code');
      nestedCodeElements.forEach((nestedCode) => {
        nestedCode.classList.add('bg-transparent', 'py-1');
      });
    });

    const tableElements = element.querySelectorAll('table');
    tableElements.forEach((table) => {
      table.classList.add('my-5', 'block', 'overflow-auto');

      const thElements = table.querySelectorAll('th');
      thElements.forEach((th) => {
        th.classList.add('border', 'border-dark-20/20', 'p-5', 'font-semibold', 'border-b-4', 'text-left');
      });

      const tdElements = table.querySelectorAll('td');
      tdElements.forEach((td) => {
        td.classList.add('p-5', 'border', 'border-dark-20/20');
      });
    });
  }
}
