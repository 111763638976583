import { Component, OnInit } from '@angular/core';
import { SectionsIdConstants } from '../../modules/shared/constants/sections-id.constants';
import { COMPANY_FULL_NAME, COMPANY_NAME } from '../../modules/shared/constants/global.constants';
import { CONTACTS_LINKS } from '../../modules/shared/constants/contacts-links.constants';

@Component({
  selector: 'rth-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  protected readonly SectionsIdConstants = SectionsIdConstants;
  protected readonly COMPANY_FULL_NAME = COMPANY_FULL_NAME;
  protected readonly COMPANY_NAME = COMPANY_NAME;

  public logoSrc: string = 'assets/images/logos/codexio-logo-vertical-monochrome-light.svg';
  public currentYear: number;
  public socialLinks = [
    {
      href: 'https://www.facebook.com/CodexioLtd/',
      iconClass: 'bx bxl-facebook-square'
    },
    {
      href: 'https://bg.linkedin.com/company/codexio',
      iconClass: 'bx bxl-linkedin-square'
    },
    {
      href: 'https://codexio.bg/',
      iconClass: 'fi fi-rs-globe'
    }
  ];
  public contacts = [
    {
      text: 'info@codexio.bg',
      href: CONTACTS_LINKS.EMAIL,
      iconClass: 'fi fi-rs-envelope'
    },
    {
      text: '+359898788998',
      href: CONTACTS_LINKS.PHONE,
      iconClass: 'fi fi-rs-phone-call'
    },
    {
      text: 'Bulgaria, Sofia, City Palace Business Centre, 24A Metodi Popov str.',
      href: CONTACTS_LINKS.OFFICE_LOCATION,
      iconClass: 'fi fi-rs-marker'
    }
  ];

  public ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
