import { EMAIL, FIRST_NAME, LAST_NAME, MESSAGE } from './validation.constants';

export const REQUIRED_FIELD = 'This field is required';

export const VALIDATION_MESSAGES = {
  FIRST_NAME: `First name should be ${FIRST_NAME.MIN_LENGTH} to ${FIRST_NAME.MAX_LENGTH} symbols long and can't include special characters.`,
  LAST_NAME: `Last name should be ${LAST_NAME.MIN_LENGTH} to ${LAST_NAME.MAX_LENGTH} symbols long and can't include special characters.`,
  MESSAGE: `"Message can't include *, ~ and consecutive spaces. It should be ${MESSAGE.MIN_LENGTH} to ${MESSAGE.MAX_LENGTH} symbols long and start with a letter."`,
  EMAIL: `Please enter valid email, length should be between ${EMAIL.MIN_LENGTH} and ${EMAIL.MAX_LENGTH} symbols.`,
};
