import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './base/base.component';
import { ROUTE_PATHS } from './modules/shared/constants/routes.constants';

const routes: Routes = [
  {
    path: ROUTE_PATHS.HOME,
    component: BaseComponent,
    children: [
      {
        path: ROUTE_PATHS.HOME,
        pathMatch: 'full',
        loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
      },
      {
        path: ROUTE_PATHS.DOCS,
        pathMatch: 'full',
        loadChildren: () => import('./modules/documentation/documentation.module').then(m => m.DocumentationModule)
      },
      {
        path: '**',
        redirectTo: ROUTE_PATHS.HOME
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
